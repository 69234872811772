body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Fredoka';
  src: local('Fredoka'), url(/static/media/FredokaOne-Regular.376fe650.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: local('Roboto-Medium'), url(/static/media/Roboto-Medium.987ab511.ttf) format('truetype');
}

@font-face {
  font-family: 'Motiva-Sans-Bold';
  src: local('Motiva-Sans-Bold'), url(/static/media/Motiva-Sans-Bold.9d56c686.ttf) format('truetype');
}

@font-face {
  font-family: 'Motiva-Sans-Light';
  src: local('Motiva-Sans-Light'), url(/static/media/Motiva-Sans-Light.5d7d3917.ttf) format('truetype');
}


@font-face {
  font-family: 'GOD';
  src: local('GOD'), url(/static/media/GODOFWAR.edd836b7.TTF) format('truetype');
}

.App {
  
  font-family: Fredoka;
  color: black;
  
  align-items: center;
  font-size: calc(9px + 1vmin);

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}




.App-header {
  background-color: #010B13;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top-center;
  font-size: calc(10px + 2vmin);
  color: white;
}


h1 {

  font-size: calc(25px + 3vmin);

}



body {
  
  

  background-color: #010B13;

  background-position: top;
  *background-repeat: no-repeat;
  background-size: cover;
  padding: 0px;
  *font-family: Arial;
  align-items: center;
  
}








.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
}


.card {


padding-top: 65px;



border-color: transparent;
background-color: transparent;

 align-content: center;
 align-items: center;

border-radius: 15px;


}

  
  
  .text-title {
  
    color: whitesmoke;
    
    font-family: Motiva-Sans-Light;
    font-size: calc(20px + 5vmin);
    color:whitesmoke;

    }




