body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Fredoka';
  src: local('Fredoka'), url(./fonts/FredokaOne-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: local('Roboto-Medium'), url(./fonts/Roboto-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Motiva-Sans-Bold';
  src: local('Motiva-Sans-Bold'), url(./fonts/Motiva-Sans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Motiva-Sans-Light';
  src: local('Motiva-Sans-Light'), url(./fonts/Motiva-Sans-Light.ttf) format('truetype');
}


@font-face {
  font-family: 'GOD';
  src: local('GOD'), url(./fonts/GODOFWAR.TTF) format('truetype');
}
